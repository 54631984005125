define('placeholderPatch',['placeholderPatch'], function(jquery){

  (function ($) {
    $.extend($.fn, {
      placeholder: function (options) {}
    });
  })(jQuery);

});

