/**
 * Store libs module
 */
define(
  'shared/store-libs',
  [
    'knockout',
    'koMapping',
    'koExternalTemplate',
    'koValidate',
    'hasher',
    'signals',
    'i18next',
    'crossroads',
    'jqueryui',
    'bootstrapDatePicker',
    'bootstrapTimePicker',
    'bstypeahead',
    'moment',
    'spectrumColorPicker',
    'trafficCop',
    'infuser',
    'imagesloaded',
    'chosen'
  ]
);

